<template>
  <label :class="['app-switcher-box', { 'app-switcher-box__toggle': type === 'toggle' }]">
    <input
      :checked="isChecked"
      class="app-switcher-box__checkbox"
      type="checkbox"
      :disabled="isDisabled"
      :tabindex="-1"
      @input="$emit('input', !isChecked)"
      @change="$emit('change')"
    />
    <span
      class="app-switcher-box__checkmark"
      :class="[{ 'type-radio': type === 'radio' }, { 'type-toggle': type === 'toggle' }]"
      :tabindex="`${isFocused ? '0' : '-1'}`"
      @keyup.enter="$emit('input')"
    />
    <app-text v-if="text" class="app-switcher-box__text" :type="typeText" :text="text" />
  </label>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import AppText from '@/ui/components/text/app-text/AppText.vue';
import { TSwitcherType } from './AppSwitcherBoxTypes';
import { IText, TextTypeEnum } from '@/ui/components/text/app-text/AppTextTypes';

export default Vue.extend({
  name: 'AppSwitcherBox',
  components: { AppText },
  props: {
    text: {
      type: String,
      required: false,
      default: '',
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
    },
    isFocused: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String as PropType<TSwitcherType>,
      required: false,
      default: 'checkbox',
    },
    typeText: {
      type: String as PropType<IText>,
      default: 'main',
      validator: (value) => Object.values(TextTypeEnum).includes(value as IText),
    },
  },
});
</script>

<style lang="scss">
@import 'style';
</style>
