<template>
  <AppModal
    v-if="isShowModal"
    :is-show-modal="isShowModal"
    @toggleModal="toggleModal"
    :text-head="currentStepForm.title"
    :is-confirm-exit="true"
  >
    <div v-if="isLoadingRegistrationForm" class="spinner-wrapper"><AppSpinner /></div>

    <div v-if="!isLoadingRegistrationForm">
      <div v-if="!isLogin" class="app-modal-registration__subtitle">
        <app-text type="primary-book" color="secondary" :text="'Уже есть аккаунт?'" />
        <div @click="openAuthModal" class="app-modal-auth__text">
          <app-text type="primary-book" color="text-link" :text="'Войдите'" />
        </div>
      </div>

      <AppSocialNetworks />

      <div class="app-modal-registration__controls">
        <div v-for="item in formFields" :key="item.key">
          <UIPhoneInput
            v-if="item.platformType === 'special.mobilePhoneNumber'"
            class="relative mb-[17px] z-50"
            size="extra-large"
            :value="form[item.key]"
            :error-messages="errors[item.key] && errors[item.key].flat()"
            @input="changeValue($event.value, item.key)"
          >
            <template #label>
              {{ item.name }}
            </template>
          </UIPhoneInput>

          <app-input
            v-else-if="
              item.platformType.includes('input') ||
              item.platformType === 'email' ||
              item.platformType.includes('special')
            "
            :id="item.key"
            v-model="form[item.key]"
            :type="
              item.platformType === 'email'
                ? 'email'
                : item.platformType === 'input.date'
                ? 'birthDate'
                : item.platformType === 'special.snils'
                ? 'snils'
                : 'text'
            "
            :label="item.name"
            :placeholder="item.placeholder"
            autocomplete="off"
            :errors="errors[item.key]"
            @onInput="changeValue($event, item.key)"
            @onBlur="checkRegister(item.platformType)"
          />

          <app-input-dropdown
            v-if="
              item.key === 'countryOfResidence' &&
              item.platformType.includes('location.country') &&
              !item.additionalOptions?.default
            "
            list-key="title"
            :label="item.name"
            :placeholder="item.placeholder ?? ''"
            :is-loading="isCountriesLoading"
            :default-value="form['countryOfResidence']"
            :list="searchCountries"
            :errors="errorCountry"
            @search="getCountries($event)"
            @onSelect="setCountry($event)"
          />

          <app-input-dropdown
            v-if="
              item.key === 'regionOfResidence' &&
              item.platformType.includes('location.region') &&
              !item.additionalOptions?.default
            "
            list-key="title"
            :label="item.name"
            placeholder="Из какого вы региона?"
            :errors="errorRegion"
            :is-loading="isRegionsLoading"
            :default-value="form['regionOfResidence']"
            :list="searchRegions"
            :disabled="!form['countryOfResidence']?.title"
            :note="!form['countryOfResidence']?.title ? 'Для заполнения введите Страну' : ''"
            @search="getRegions($event)"
            @onSelect="setRegion($event)"
          />

          <app-input-dropdown
            v-if="
              item.key === 'cityOfResidence' &&
              item.platformType.includes('location.city') &&
              !item.additionalOptions?.default
            "
            list-key="title"
            label="Населённый пункт"
            :placeholder="item.placeholder ?? ''"
            :errors="errorCity"
            :is-loading="isCitiesLoading"
            :default-value="form['cityOfResidence']"
            :list="searchCities"
            :disabled="!form['regionOfResidence']?.title"
            :note="!form['regionOfResidence']?.title ? 'Для заполнения введите Регион' : ''"
            @search="getCities($event)"
            @onSelect="setCity($event)"
          />

          <app-input-dropdown
            v-if="item.platformType.includes('location.municipality')"
            list-key="title"
            :label="item.name"
            :placeholder="item.placeholder ?? ''"
            :errors="errorMunicipality"
            :is-loading="isMunicipalitiesLoading"
            :default-value="form[item.key]"
            :list="searchMunicipalities"
            :note="'Если в списке нет подходящего Муниципалитета, укажите его в ручную или продублируйте название Города'"
            @search="getMunicipality($event)"
            @onSelect="setMunicipality($event)"
          />

          <app-input
            v-if="item.key === 'password'"
            :id="item.key"
            v-model="form[item.key]"
            :label="item.name"
            :placeholder="item.placeholder"
            :value="password"
            :type="isPassHidden ? 'password' : 'text'"
            autocomplete="new-password"
            :errors="errors[item.key]"
            class="app-modal-registration__input-password"
            note="Пароль должен иметь не менее 8 символов, строчные и прописные буквы и цифры"
            @onInput="changeValue($event, item.key)"
          >
            <template #append>
              <div
                class="app-modal-registration__password-controller"
                @click="isPassHidden = !isPassHidden"
              >
                <app-icon :name="isPassHidden ? 'eye-off' : 'eye'" />
              </div>
            </template>
          </app-input>

          <app-select
            v-if="item.platformType === 'select.one'"
            :id="item.key"
            :label="item.name"
            :title-text="getSelectText(item)"
            list-key="name"
            :items="getSelectList(item)"
            :errors="errors[item.key]"
            class="app-modal-registration__select"
            @onSelect="setSelected($event, item.key)"
          />

          <div v-if="item.platformType === 'select.multiple'" class="registration-multiselect">
            <div class="registration-multiselect-label">{{ item.name }}</div>
            <UIMultiselect
              :selected-list="form[item.key]?.map((listItem) => ({ name: listItem })) ?? []"
              :options-list="getSelectList(item)"
              key-field="name"
              label-field="name"
              :error-messages="flatten(errors[item.key])"
              @update="updateMultiselect(item.key, $event)"
            />
          </div>

          <app-check-box
            v-if="item.platformType === 'checkbox'"
            type="checkbox"
            :text="item.name"
            :is-checked="form[item.key]"
            class="app-modal-registration__switcher"
            @change="setCheckbox(item.key)"
          />
        </div>

        <app-button
          :text="currentStepForm.buttonText"
          web-type="submit"
          :is-disabled="isButtonDisabled"
          @click="submit"
        />
        <app-button
          v-if="currentStep != 0"
          text="К предыдущему шагу"
          type="secondary"
          class="app-modal-registration__back-button"
          @click="goPreStep"
        />
      </div>

      <div v-if="isLastStep" class="app-modal-registration__forgot-container">
        <app-text
          type="primary-book"
          color="secondary"
          :text="`Нажимая кнопку «${currentStepForm.buttonText}», вы принимаете условия `"
        />
        <div>
          <AppButtonLink
            :link="registrationForm.userAgreement"
            title="пользовательского соглашения"
            font-type="primary-book"
            target="_blank"
            color="text-link"
          />
          <AppText type="primary-book" color="secondary" text="и" />
          <AppButtonLink
            :link="registrationForm.privacyPolicy"
            title="политики конфиденциальности"
            font-type="primary-book"
            target="_blank"
            color="text-link"
          />
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script lang="ts">
import Vue from 'vue';
import AppText from '@/ui/components/text/app-text/AppText.vue';
import AppModal from '@/ui/components/modals/app-modal';
import { AppSocialNetworks } from '@/components/modals';
import AppInput from '@/ui/components/inputs/app-input/AppInput.vue';
import AppButton from '@/ui/components/buttons/app-button/AppButton.vue';
import { AppIcon } from '@/ui/components/icons';
import AppCheckBox from '@/ui/components/switchers/app-check-box/AppCheckBox.vue';
import { EX_$Modal } from '@/classes/content';
import MAuth from '@/mixins/MAuth';
import { mapActions, mapState } from 'vuex';
import { IRegistrationField, IRegistrationStep } from '@/services/AuthService/AuthServiceTypes';
import AppSelect from '@/ui/components/selects/app-select/AppSelect.vue';
import EX_TokensAuth from '@/classes/base/token-cookies';
import EX_Toaster from '@/classes/content/toast';
import AppInputDropdown from '@/ui/components/inputs/app-input-dropdown/AppInputDropdown.vue';
import AppSpinner from '@/components/common/app-spinner/AppSpinner.vue';
import { useAuthStore } from '@/store/pinia/auth/auth.store';
import { YandexMetrikaUtil } from '@/utils/yandex-metrika.util';
import { AppButtonLink } from '@/ui/components';
import { StatisticsApi } from '@/api/apis/StatisticsApi';
import UIPhoneInput from '@/components/UI/UIPhoneInput/UIPhoneInput.vue';
import UIMultiselect from '@/components/UI/UIMultiselect.vue';
import { flatten, cloneDeep, omit } from 'lodash';
import { RegistrationApi } from '@/api/apis/RegistrationApi';

export default Vue.extend({
  name: 'AppModalRegistration',
  components: {
    UIMultiselect,
    UIPhoneInput,
    AppButtonLink,
    AppSpinner,
    AppSelect,
    AppCheckBox,
    AppButton,
    AppInput,
    AppText,
    AppModal,
    AppSocialNetworks,
    AppIcon,
    AppInputDropdown,
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [MAuth],
  data() {
    return {
      EX_$ModalClass: EX_$Modal,
      email: '',
      password: '',
      name: '',
      isPassHidden: true,
      currentStep: 0,
      //TODO::нужен тип
      form: {} as any,
      errors: {},
      errorCountry: [] as Array<string>,
      errorRegion: [] as Array<string>,
      errorCity: [] as Array<string>,
      errorMunicipality: [] as Array<string>,
      idTimeCountry: 0 as any,
      idTimeRegion: 0 as any,
      idTimeCity: 0 as any,
      idTimeMunicipality: 0 as any,
      isLoadingRegistrationForm: true,
    };
  },
  methods: {
    flatten,
    cloneDeep,
    omit,
    ...mapActions('auth', [
      'ARegistration',
      'ASetIsLogin',
      'AValidateRegistrationStep',
      'AGetSearchCities',
      'AGetSearchRegions',
      'AGetSearchCountries',
      'AGetSearchMunicipalities',
      'AClearRegions',
      'AClearCities',
      'AClearCountries',
      'AClearMunicipalities',
    ]),
    ...mapActions('event', ['AGetMyEventList']),
    toggleModal() {
      this.clearData();
      return this.$emit('toggleModal');
    },
    openAuthModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalAuth');
    },
    openRecoveryModal() {
      this.toggleModal();
      this.EX_$ModalClass.toggleModal('appModalRecoveryByEmail');
    },
    changeValue(value: string | number, key: string) {
      Vue.set(this.form, key, value);
    },
    getSelectList(item: IRegistrationField) {
      const list = item.selectValues ? item.selectValues : item.additionalOptions?.selectValues;
      return list?.length
        ? list.map((item) => {
            return { name: item };
          })
        : [];
    },
    setSelected(value: any, key: string) {
      Vue.set(this.form, key, value.name);
    },
    updateMultiselect(key: string, value: { name: string }[]) {
      Vue.set(
        this.form,
        key,
        value.map((item) => item.name)
      );
    },
    setCheckbox(key: string) {
      Vue.set(this.form, key, !this.form[key]);
    },
    clearData() {
      this.form = {};
      this.currentStep = 0;
      this.errors = {};
    },
    goPreStep() {
      this.currentStep--;
      document.getElementById('appModalTop')?.scrollIntoView();
    },
    getSelectText(item: IRegistrationField) {
      return item.placeholder ? item.placeholder : item.additionalOptions?.placeholder;
    },
    setCountry(value: { id: number; title: string }) {
      if ('countryOfResidence' in this.form) {
        Vue.set(this.form, 'countryOfResidence', value);
      }

      this.setRegion({ id: 0, title: '' });
      this.setCity({ id: 0, title: '' });
    },
    setRegion(value: { id: number; title: string }) {
      if ('regionOfResidence' in this.form) {
        Vue.set(this.form, 'regionOfResidence', value);
      }

      this.setCity({ id: 0, title: '' });
    },
    setCity(value: { id: number; title: string }) {
      if ('cityOfResidence' in this.form) {
        Vue.set(this.form, 'cityOfResidence', value);
      }
    },

    setMunicipality(value: { id: number; title: string }) {
      if ('municipalityOfResidence' in this.form) {
        Vue.set(this.form, 'municipalityOfResidence', value);
      }
    },
    getCountries(value: string) {
      clearInterval(this.idTimeCountry);
      if (value.length >= 3) {
        this.idTimeCountry = setTimeout(() => {
          this.AGetSearchCountries(value);
        }, 300);
      } else {
        this.setCountry({ id: 0, title: '' });
        this.AClearCountries();
      }
    },
    getRegions(value: string) {
      clearInterval(this.idTimeRegion);
      if (value.length >= 3) {
        this.idTimeRegion = setTimeout(() => {
          this.AGetSearchRegions({
            countryId: this.form['countryOfResidence']?.id,
            search: value,
          });
        }, 300);
      } else {
        this.setRegion({ id: 0, title: '' });
        this.AClearRegions();
      }
    },
    getCities(value: string) {
      clearTimeout(this.idTimeCity);
      if (value.length >= 3) {
        this.idTimeCity = setTimeout(() => {
          this.AGetSearchCities({
            countryId: this.form['countryOfResidence']?.id,
            regionId: this.form['regionOfResidence']?.id,
            regionName: this.form['regionOfResidence']?.title,
            search: value,
          });
        }, 300);
      } else {
        this.setCity({ id: 0, title: '' });
        this.AClearCities();
      }
    },

    getMunicipality(value: string) {
      clearTimeout(this.idTimeMunicipality);
      if (value.length >= 3) {
        this.idTimeMunicipality = setTimeout(() => {
          this.AGetSearchMunicipalities({
            countryId: this.form['countryOfResidence']?.id,
            regionId: this.form['regionOfResidence']?.id,
            regionName: this.form['regionOfResidence']?.title,
            cityId: this.form['cityOfResidence']?.id,
            search: value,
          });
        }, 300);
        if (!this.searchMunicipalities.length) {
          this.setMunicipality({ id: 0, title: value });
        }
      } else {
        this.setMunicipality({ id: 0, title: '' });
        this.AClearMunicipalities();
      }
    },
    adaptFormBeforeSend(form: any) {
      const result = cloneDeep(form);
      for (const key in result) {
        switch (key) {
          case 'countryOfResidence':
          case 'regionOfResidence':
          case 'cityOfResidence':
          case 'municipalityOfResidence':
            {
              const temp = result[key];
              if (temp) result[key] = { id: temp.id, name: temp.title };
            }
            break;
        }
      }
      return result;
    },
    submit() {
      this.errors = {};
      const adaptedForm = this.adaptFormBeforeSend(this.form);
      //ВАЛИДАЦИЯ ПОЛЕЙ ФОРМЫ
      this.AValidateRegistrationStep(adaptedForm)
        .then(() => {
          if (this.isLastStep) {
            //ПОСЛЕДНИЙ ШАГ РЕГИСТРАЦИИ
            const payload = {
              eventUuid: this.eventInfo.uuid,
              body: adaptedForm,
            };
            this.ARegistration(payload)
              .then((res) => {
                if (!this.isLogin) {
                  EX_TokensAuth.setAccessToken(res.accessToken);
                  EX_TokensAuth.setRoleInfo(res.accessToken);
                  EX_TokensAuth.setRefreshToken(res.refreshToken);
                  this.ASetIsLogin(true);
                  useAuthStore().setIsLoggedIn(true);
                  EX_Toaster.success('Успешная регистрация');

                  // TODO убрать ям
                  if (this.$route.name === 'EventPage') YandexMetrikaUtil.reachGoalVisitEventPage();
                  if (this.$route.name === 'EventPage') StatisticsApi.visitEventPage();
                }
                this.toggleModal();
                this.EX_$ModalClass.toggleModal('appModalRegistrationSuccess');
                this.AGetMyEventList();
              })
              .catch((e) => {
                let element: HTMLElement | null = null;
                const errorData = e.response.data;
                if (errorData.errors === 'event.sign_up.some_fields_missing') {
                  //находим поля, обязательные для заполнения
                  errorData.data.missingFields.map((item: string) => {
                    if (!element) element = document.getElementById(item);
                    Vue.set(this.errors, item, ['Заполните поле']);
                  });
                }
                if (errorData.message === 'validation.error') {
                  //находим поля, непрошедшие валидацию
                  for (let item in errorData.errors) {
                    if (!element) element = document.getElementById(item);
                    Vue.set(this.errors, item, [errorData.errors[item]]);
                  }
                }
                if (errorData.message === 'auth.user_already_exist') {
                  let stepEmail: number | null = null;
                  //находим на каком шаге находится поле email
                  this.registrationForm.steps.map((item: IRegistrationStep, index: number) => {
                    item.fields?.map((field: IRegistrationField) => {
                      if (!stepEmail && field['key'] === 'email') {
                        stepEmail = index;
                      }
                    });
                  });
                  if (stepEmail != null) this.currentStep = stepEmail;
                  Vue.set(this.errors, 'email', [
                    'Пользователь с таким e-mail уже существует. Для регистрации на событие вам необходимо Войти',
                  ]);
                  element = document.getElementById('email');
                }

                if (element) element.scrollIntoView({ behavior: 'smooth' });
              });
          } else {
            //НЕПОСЛЕДНИЙ ЭТАП
            this.currentStep = this.currentStep + 1;
            document.getElementById('appModalTop')?.scrollIntoView();
          }
        })
        .catch((e) => {
          let stepField: number | null = null;
          let nameField = '';
          let element: HTMLElement | null = null;

          //добавляем тексты ошибок к полям
          const errorData = e.response.data;
          if (errorData.message === 'validation.error') {
            for (let item in errorData.errors) {
              if (!element) element = document.getElementById(item);
              Vue.set(this.errors, item, [errorData.errors[item]]);
              if (!nameField) nameField = item;
            }
          }
          //находим на каком шаге находится первое поле с ошибкой
          this.registrationForm.steps.map((item: IRegistrationStep, index: number) => {
            item.fields?.map((field: IRegistrationField) => {
              if (!stepField && field['key'] === nameField) {
                stepField = index;
              }
            });
          });
          if (stepField != null) this.currentStep = stepField;
          if (element) element.scrollIntoView({ behavior: 'smooth' });
        });
    },
    async fetchRegistrationForm() {
      this.isLoadingRegistrationForm = true;
      const slug = this.$route.params.slug;
      if (slug) {
        await this.$store.dispatch('auth/AGetRegistrationForm', slug);
      }
      // TODO Обработать ситуацию если slug нет - отобразить об этом ошибку вместо лоадера
      this.isLoadingRegistrationForm = false;
    },
    setDefaultForm() {
      for (const step of this.registrationForm.steps) {
        step.fields?.forEach((item: IRegistrationField) => {
          let value;
          // Если у формы есть значение по умолчанию, выставляем их
          value = item?.additionalOptions?.default;

          Vue.set(this.form, item.key, value);
        });
      }
    },
    async checkRegister(type: string) {
      if (type !== 'email' || !this.form.email) {
        return;
      }

      try {
        await RegistrationApi.checkIsEmailExist(this.form.email);
        this.errors = omit(this.errors, 'email');
      } catch (e: any) {
        this.errors = { ...this.errors, email: e.response.data.errors.email };
      }
      return;
    },
  },
  computed: {
    ...mapState('auth', [
      'registrationForm',
      'isLogin',
      'searchCities',
      'isCitiesLoading',
      'searchRegions',
      'isRegionsLoading',
      'searchCountries',
      'isCountriesLoading',
      'searchMunicipalities',
      'isMunicipalitiesLoading',
    ]),
    ...mapState('event', ['eventInfo']),
    ...mapState('profile', ['profileInfo']),
    currentStepForm(): IRegistrationStep {
      return this.registrationForm.steps[this.currentStep] ?? {};
    },
    formFields(): IRegistrationField[] | null {
      return this.currentStepForm ? this.currentStepForm.fields ?? null : null;
    },
    isButtonDisabled(): boolean {
      let isDisabled = false;
      this.formFields?.map((item: IRegistrationField) => {
        if (item.required && !this.form[item.key]) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
    isLastStep(): boolean {
      return this.registrationForm.steps.length - 1 === this.currentStep;
    },
  },
  async mounted() {
    await this.fetchRegistrationForm();
    // На данный момент может использоваться только один набор "Страна/Регион/Город" в форме
    this.setDefaultForm();
  },
});
</script>

<style lang="scss">
@use 'style';

.spinner-wrapper {
  height: 500px;
  width: 100%;
  position: relative;
}
.registration-multiselect {
  margin-bottom: 17px;
  &-label {
    color: var(--main-text-color);
    margin-bottom: 5px;
  }
  .selected-empty {
    height: 40px;
  }
  .selected-empty-text {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .registration-multiselect {
    &-label {
      font-size: 14px;
    }
    .selected-empty {
      height: 32px;
    }
    .selected-empty-text {
      font-size: 16px;
    }
  }
}
</style>
