<template>
  <label
    class="ui-input"
    :class="{
      [size]: true,
      error: errorMessages?.length,
      round,
    }"
  >
    <span v-if="checkIsSlotFilled($slots['label'])" class="ui-input-label">
      <slot name="label" />

      <UIPopover v-if="checkIsSlotFilled($slots['label-info'])" trigger="hover" placement="top">
        <template #control>
          <UIIcon name="info" :size="20" class="stroke-gray hover:stroke-client fill-none" />
        </template>

        <div class="text-start"><slot name="label-info" /></div>
      </UIPopover>
    </span>

    <span class="w-full relative flex">
      <!--  TODO учесть захардкоженное при появлении размеров-->
      <span
        class="absolute left-4 top-1/2 transform -translate-y-1/2"
        ref="leftPlaceRef"
        v-if="checkIsSlotFilled($slots.leftPlace)"
      >
        <slot name="leftPlace"></slot>
      </span>

      <input
        v-if="tag === 'input'"
        :id="inputId"
        :value="value"
        v-bind="$attrs"
        type="text"
        class="ui-input-input"
        :class="{
          'bg-neutral-100': !backgroundWhite,
          'bg-white': backgroundWhite,
        }"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :style="`${paddingLeft} ${paddingRight}`"
        :inputmode="inputMode"
        @input="onInput"
        @keydown="$emit('keydown', $event)"
        @paste="$emit('paste', $event)"
        @blur="$emit('blur', $event)"
      />
      <textarea
        v-if="tag === 'textarea'"
        :id="inputId"
        :value="value"
        v-bind="$attrs"
        class="ui-input-input resize-y"
        :class="{
          'bg-neutral-100': !backgroundWhite,
          'bg-white': backgroundWhite,
        }"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :inputmode="inputMode"
        :style="`${paddingRight}`"
        :rows="textAreaRows"
        @input="onInput"
        @keydown="$emit('keydown', $event)"
        @paste="$emit('paste', $event)"
      />

      <!--  TODO учесть захардкоженное при появлении размеров-->
      <span
        v-if="errorMessages?.length"
        ref="iconsRefRight"
        class="absolute"
        :class="{
          'top-1/2 transform -translate-y-1/2': tag === 'input',
          'top-5': tag === 'textarea' && size === 'extra-large',
          'right-4': size === 'extra-large',
          'right-2.5': size === 'small' || size === 'medium',
        }"
      >
        <UIIcon name="info" class="stroke-red-500 fill-none" />
      </span>

      <!--  TODO учесть захардкоженное при появлении размеров-->
      <span
        class="absolute right-4 top-1/2 transform -translate-y-1/2"
        ref="rightPlaceRef"
        v-if="checkIsSlotFilled($slots.rightPlace)"
      >
        <slot name="rightPlace"></slot>
      </span>
    </span>

    <span v-if="maxLength !== undefined && isMaxLengthVisible" class="ui-input-length">
      {{ String(value).length }} / {{ maxLength }}
    </span>

    <span v-if="errorMessages?.length" class="ui-input-error-messages">
      <span v-for="message in errorMessages" :key="message">
        {{ message }}
      </span>
    </span>
  </label>
</template>

<script lang="ts">
export default { name: 'UIInput', inheritAttrs: false };
// Ссылка на UI kit в макете https://www.figma.com/file/CRKluP0CXR31FcB24lvW8Q/%E2%9D%87%EF%B8%8F-%D0%9C%D0%9C%D0%A1%D0%9E.%D0%9A%D0%9E%D0%9D%D0%9D%D0%95%D0%9A%D0%A2?type=design&node-id=13419-121886&mode=dev
</script>

<script setup lang="ts">
import { TUIInputSize } from '@/helpers/types/ui.types';
import { computed, onMounted, ref } from 'vue';
import UIIcon from '@/components/UI/UIIcon/UIIcon.vue';
import UIPopover from '@/components/UI/UIPopover.vue';
import { checkIsSlotFilled } from '@/helpers/methods/slots.method';

const props = withDefaults(
  defineProps<{
    value?: string | number;
    placeholder: string;
    size: TUIInputSize;
    // successMessages?: string[];
    errorMessages?: string[];
    maxLength?: number;
    // Выставляется false в случаях, когда мы должны не дать юзеру ввести больше символов чем нужно,
    // но отображать количество символов не нужно, Например при вводе номера телефона
    isMaxLengthVisible?: boolean;
    tag?: 'input' | 'textarea';
    textAreaRows?: number;
    onlyNumbers?: boolean;
    maxValue?: number;
    backgroundWhite?: boolean;
    round?: boolean;
    inputId?: string;
  }>(),
  {
    isMaxLengthVisible: true,
    tag: 'input',
    textAreaRows: 4,
    backgroundWhite: false,
    inputId: 'ui-input',
  }
);
const emits = defineEmits<{
  (event: 'input', value: string | number): void;
  (event: 'keydown', value: any): void;
  (event: 'paste', value: any): void;
  (event: 'blur', value: any): void;
}>();

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (props.onlyNumbers) {
    target.value = target.value.replace(/\D/g, '');
  }

  if (inputMode.value === 'numeric') {
    if (checkMaxValue(target.value)) {
      target.value = String(props.maxValue);
      emits('input', Number(props.maxValue));
    } else {
      emits('input', Number(target.value));
    }
  } else {
    emits('input', target.value);
  }
};

const leftPlaceRef = ref<HTMLSpanElement | null>(null);
const rightPlaceRef = ref<HTMLSpanElement | null>(null);

const iconsRefRight = ref<HTMLSpanElement | null>(null);

const DEFAULT_PADDING = {
  'extra-large': 16,
  large: 16,
  medium: 10,
  small: 10,
};
const paddingLeft = computed(() => {
  const defaultPadding = DEFAULT_PADDING[props.size];
  // добавляем еще один дефолтный отступ, так как у самого блока иконок тоже есть отступ справа
  const leftIconsResWidth = leftPlaceRef.value?.clientWidth
    ? leftPlaceRef.value.clientWidth + 8
    : 0;
  return `padding-left: ${defaultPadding + leftIconsResWidth}px;`;
});
const paddingRight = computed(() => {
  const defaultPadding = DEFAULT_PADDING[props.size];
  const iconWidth = iconsRefRight.value?.clientWidth ?? 0;
  const placeWidth = rightPlaceRef.value?.clientWidth ?? 0;
  // добавляем еще один дефолтный отступ, так как у самого блока иконок тоже есть отступ справа
  const rightIconsWidth = iconWidth || placeWidth ? defaultPadding + placeWidth + iconWidth : 0;
  return `padding-right: ${defaultPadding + rightIconsWidth}px;`;
});

const inputMode = computed<'numeric' | 'text'>(() => {
  if (typeof props.value === 'number' || props.onlyNumbers) {
    return 'numeric';
  } else {
    return 'text';
  }
});
const checkMaxValue = (value: number | string) => {
  return (
    inputMode.value === 'numeric' && props.maxValue !== undefined && Number(value) > props.maxValue
  );
};
onMounted(() => {
  if (checkMaxValue(props.value ?? 0)) {
    emits('input', Number(props.maxValue));
  }
});
</script>

<style lang="scss" scoped>
.ui-input {
  @apply box-border flex flex-col;

  &-label {
    @apply flex items-center;
  }

  &-input {
    @apply appearance-none box-border w-full flex items-center border-none font-base;
    &::placeholder {
      @apply font-base text-gray;
    }
    &:focus {
      @apply text-black-50 outline-blue;
    }
  }

  &-length {
    @apply text-gray;
  }

  &-error-messages {
    @apply flex flex-col;
    > span {
      @apply text-red-500;
    }
  }
}
.extra-large {
  @apply gap-1;
  .ui-input {
    &-label {
      @apply gap-1 text-base lg:text-base/5;
    }

    &-input {
      @apply text-lg/7 py-4 pl-4;
      &::placeholder {
        @apply text-lg/7;
      }
    }

    &-length {
      @apply text-xs;
    }

    &-error-messages {
      @apply gap-1;
      > span {
        @apply text-xs;
      }
    }
  }
}
.medium {
  @apply gap-1;
  .ui-input {
    &-label {
      @apply gap-1 lg:text-base/4;
    }

    &-input {
      @apply text-lg/5 py-2.5 pl-2.5 rounded-sm;
      &::placeholder {
        @apply text-lg/5;
      }
    }

    &-length {
      @apply text-xs;
    }

    &-error-messages {
      @apply gap-1;
      > span {
        @apply text-xs;
      }
    }
  }
}
.extra-large:not(.round) {
  .ui-input-input {
    @apply rounded;
  }
}
.extra-large.round {
  .ui-input-input {
    @apply rounded-[50px];
  }
}

.large {
  @apply gap-1;
  .ui-input {
    &-label {
      @apply gap-1 text-sm lg:text-sm/2;
    }

    &-input {
      @apply text-base lg:text-base/4 py-4 pl-4;
      &::placeholder {
        @apply text-base lg:text-base/4;
      }
    }

    &-length {
      @apply text-xs;
    }

    &-error-messages {
      @apply gap-1;
      > span {
        @apply text-xs;
      }
    }
  }
}
.large:not(.round) {
  .ui-input-input {
    @apply rounded-md;
  }
}
.large.round {
  .ui-input-input {
    @apply rounded-[50px];
  }
}

.medium {
  @apply gap-1;
  .ui-input {
    &-label {
      @apply gap-1 lg:text-base/4;
    }

    &-input {
      @apply text-lg/5 py-2.5 pl-2.5;
      &::placeholder {
        @apply text-lg/5;
      }
    }

    &-length {
      @apply text-xs;
    }

    &-error-messages {
      @apply gap-1;
      > span {
        @apply text-xs;
      }
    }
  }
}
.medium:not(.round) {
  .ui-input-input {
    @apply rounded-sm;
  }
}
.medium.round {
  .ui-input-input {
    @apply rounded-[50px];
  }
}

.small {
  @apply gap-1;
  .ui-input {
    &-label {
      @apply gap-1 text-base/5;
    }

    &-input {
      @apply text-lg/5 py-2.5 pl-2.5;
      &::placeholder {
        @apply text-lg/5;
      }
    }

    &-length {
      @apply text-xs;
    }

    &-error-messages {
      @apply gap-1;
      > span {
        @apply text-xs;
      }
    }
  }
}
.small:not(.round) {
  .ui-input-input {
    @apply rounded-sm;
  }
}
.small.round {
  .ui-input-input {
    @apply rounded-[46px];
  }
}

.error {
  .ui-input-input {
    @apply bg-red-50;
    &:focus {
      @apply outline-red-500;
    }
  }
}
</style>
